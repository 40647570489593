<template>
  <b-modal
    id="modal-question"
    v-model="show"
    :title="title"
    :size="showAnswers ? 'xl' : 'lg'"
    centered
    hide-footer
    @hidden="resetModal"
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <b-row v-if="isCreate">
          <b-col>
            <b-button
              class="btn btn-success"
              type="button"
              @click="addQuizeAvailable"
            >
              Chọn câu hỏi có sẵn
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="5" :class="{ 'border-right': showAnswers }">
            <b-row>
              <b-col>
                <basic-input
                  label="Mã câu hỏi"
                  required
                  placeholder="Nhập mã câu hỏi"
                  name="code"
                  :state="validateState(`code`)"
                  :invalid-feedback="errors.first(`code`)"
                  v-validate="{
                    required: true,
                    valid_question_code: true,
                    max: 50,
                    valid_code: validCode,
                  }"
                  data-vv-as="Mã câu hỏi"
                  class="score-input"
                  :value.sync="form.code"
                  :disabled="isDetail || !!form.source"
                  maxlength="50"
                >
                </basic-input>
              </b-col>
              <b-col>
                <basic-select
                  required
                  label="Số thứ tự câu hỏi"
                  placeholder="--- Chọn số thứ tự câu hỏi ---"
                  name="order"
                  :solid="false"
                  :allowEmpty="false"
                  value-label="text"
                  track-by="value"
                  :options="orders"
                  :value.sync="form.order"
                  :state="validateState(`order`)"
                  :invalid-feedback="errors.first(`order`)"
                  data-vv-as="Số thứ tự câu hỏi"
                  v-validate="'required'"
                  :disabled="isDetail"
                  @update:value="selectOrder"
                />
              </b-col>
            </b-row>
            <basic-input
              label="Tiêu đề câu hỏi"
              required
              placeholder="Nhập tiêu đề câu hỏi"
              name="name"
              :value.sync="form.name"
              :state="validateState(`name`)"
              :invalid-feedback="errors.first(`name`)"
              data-vv-as="Tiêu đề câu hỏi"
              v-validate="'required|max:500'"
              :disabled="isDetail || !!form.source"
              maxlength="500"
            ></basic-input>
            <basic-select
              required
              label="Loại câu hỏi"
              placeholder="--- Chọn loại câu hỏi ---"
              name="type"
              :solid="false"
              :allowEmpty="false"
              value-label="text"
              track-by="value"
              :options="questionTypes"
              :value.sync="form.type"
              :state="validateState(`type`)"
              :invalid-feedback="errors.first(`type`)"
              data-vv-as="Tiêu đề câu hỏi"
              v-validate="'required'"
              :disabled="isDetail || !!form.source || isRelatedQuestion"
              @update:value="selectType"
            />
            <b-form-group v-if="showScoreCheckbox" label="Tính điểm">
              <b-form-checkbox
                id="checkbox-score"
                v-model="form.isScore"
                name="checkbox-score"
                :disabled="isDetail"
              >
                Tính điểm trên từng đáp án
              </b-form-checkbox>
            </b-form-group>
            <b-form-group label="Điều kiện hiển thị (nếu có)">
              <b-form-checkbox
                id="checkbox-related-question"
                v-model="form.isRelatedQuestions"
                name="checkbox-related-question"
                :disabled="
                  isDetail ||
                  (questions.length && form.order && form.order.value === 1)
                "
                @change="changeRelatedQuestions"
              >
                Liên quan đến câu hỏi khác
              </b-form-checkbox>
              <b-form-checkbox
                id="checkbox-related-patient"
                v-model="form.isRelatedPatients"
                name="checkbox-related-patient"
                :disabled="isDetail"
                @change="changeRelatedPatients"
              >
                Liên quan đến thông tin profile
              </b-form-checkbox>
            </b-form-group>
            <b-row v-if="form.isRelatedQuestions || form.isRelatedPatients">
              <b-col cols="12" class="py-0">
                <basic-select
                  required
                  label="Nếu"
                  placeholder="--- Câu hỏi ---"
                  name="realted-question"
                  :solid="false"
                  :allowEmpty="false"
                  value-label="name"
                  track-by="id"
                  :disabled="isDetail"
                  :options="relatedQuestions"
                  changeValueByObject
                  :value.sync="form.relatedQuestion"
                  :state="validateState(`realted-question`)"
                  :invalid-feedback="errors.first(`realted-question`)"
                  v-validate="{
                    required: form.isRelatedQuestions,
                  }"
                  data-vv-as="Câu hỏi"
                  @update:value="selectedQuestion"
                />
              </b-col>
              <b-col cols="12" class="py-0">
                <basic-input
                  label="Điều kiện"
                  :placeholder="form.isRelatedQuestions ? 'Chứa đáp án' : 'Là'"
                  name="condition"
                  disabled
                ></basic-input>
              </b-col>
              <b-col cols="12" class="py-0">
                <basic-select
                  required
                  label="Câu trả lời"
                  placeholder="--- Đáp án ---"
                  name="related-answer"
                  :solid="false"
                  :allowEmpty="false"
                  value-label="content"
                  track-by="id"
                  :disabled="isDetail"
                  :value.sync="form.relatedAnswer"
                  :options="answersOfQuestion"
                  changeValueByObject
                  :state="validateState(`related-answer`)"
                  :invalid-feedback="errors.first(`related-answer`)"
                  v-validate="{
                    required: form.isRelatedQuestions,
                  }"
                  data-vv-as="Đáp án"
                  @update:value="$validator.reset({ name: `related-answer` })"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="7" v-if="showAnswers">
            <div class="font-weight-bolder mb-2">
              Định nghĩa danh sách câu trả lời
            </div>
            <v-data-table
              v-if="!isRating"
              :headers="headers"
              :items="answers"
              id="sortable"
              hide-default-footer
              ref="sortableTable"
              :items-per-page="-1"
            >
              <template v-slot:item="{ item }">
                <!-- :key="item.id" -->
                <tr
                  v-if="!item.isAddNew"
                  :key="itemKey(item)"
                  class="cursor-pointer sortableRow position-static"
                >
                  <td
                    v-if="!isDetail && !form.source"
                    class="mw-100 handle text-overflow"
                  >
                    <div class="d-flex w-100 align-items-center">
                      <div class="svg-icon svg-icon-sm text-overflow">
                        <inline-svg
                          class="svg-icon mr-2 mw-30 sortHandle"
                          src="/media/svg/icons/Neolex/Basic/menu-dots.svg"
                        />
                      </div>
                    </div>
                  </td>
                  <td>{{ item.name }}</td>
                  <td class="py-4">
                    <basic-input
                      :name="`score-${item.id}`"
                      :value.sync="item.score"
                      :required="true"
                      :state="validateState(`score-${item.id}`)"
                      :invalid-feedback="errors.first(`score-${item.id}`)"
                      v-validate="{
                        required: form.isScore && !item.score,
                        max_value: 100,
                      }"
                      data-vv-as="Điểm"
                      class="score-input"
                      input-number
                      maxlength="3"
                      :disabled="!form.isScore"
                    ></basic-input>
                  </td>
                  <td v-if="!isDetail && !form.source">
                    <b-button
                      class="btn btn-delete ml-0"
                      size="sm"
                      type="button"
                      @click="removeAnswer(item)"
                    >
                      <span class="svg-icon mr-0">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Basic/trash-2.svg"
                        />
                      </span>
                    </b-button>
                  </td>
                </tr>
                <tr v-else class="cursor-pointer sortableRow position-static">
                  <td></td>
                  <td>
                    <div class="py-3">
                      <basic-input
                        :name="`add-answer`"
                        :value.sync="item.name"
                        :required="true"
                        :state="validateState(`add-answer`)"
                        :invalid-feedback="errors.first(`add-answer`)"
                        v-validate="{
                          required: isRequireAnswer,
                          max: 500,
                        }"
                        data-vv-as="Câu trả lời"
                        class="score-input"
                        placeholder="Thêm câu trả lời"
                        maxlength="500"
                      ></basic-input>
                    </div>
                  </td>
                  <td>
                    <basic-input
                      :name="`score`"
                      :value.sync="item.score"
                      :required="true"
                      :state="validateState(`score`)"
                      :invalid-feedback="errors.first(`score`)"
                      v-validate="{
                        required: form.isScore && isRequireScore,
                        max_value: 100,
                      }"
                      data-vv-as="Điểm"
                      class="score-input"
                      input-number
                      maxlength="3"
                      :disabled="!form.isScore"
                    ></basic-input>
                  </td>
                  <td>
                    <b-button
                      class="btn btn-save ml-0"
                      size="sm"
                      type="button"
                      @click="addAnswer(item)"
                    >
                      <span class="svg-icon mr-0">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Basic/save.svg"
                        />
                      </span>
                    </b-button>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <template v-else>
              <template v-for="(answer, index) in ratingAnswers">
                <b-row v-if="answer.isAddNew" :key="index" class="my-2">
                  <b-col cols="4">
                    <basic-select
                      name="flag"
                      :solid="false"
                      :allowEmpty="false"
                      value-label="text"
                      track-by="value"
                      class="mb-0 mx-4"
                      :options="flags"
                      :value.sync="answer.flag"
                      changeValueByObject
                      :state="validateState(`flag`)"
                      :invalid-feedback="errors.first(`flag`)"
                      v-validate="{
                        required: isRatingRequired,
                      }"
                      data-vv-as="Mốc"
                      @update:value="selectedFlag"
                    />
                  </b-col>
                  <b-col>
                    <basic-input
                      :name="`explain`"
                      :value.sync="answer.name"
                      :state="validateState(`explain`)"
                      :invalid-feedback="errors.first(`explain`)"
                      v-validate="{
                        required: isRatingRequired,
                      }"
                      data-vv-as="Mô tả"
                      class="score-input"
                      placeholder="Nhập mô tả"
                      :disabled="isDetail"
                    ></basic-input>
                  </b-col>
                  <b-col cols="2">
                    <b-button
                      class="btn btn-save ml-0 mt-1"
                      size="sm"
                      type="button"
                      @click="addRatingAnswer(answer)"
                    >
                      <span class="svg-icon mr-0">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Basic/save.svg"
                        />
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row v-else :key="index" class="my-2">
                  <b-col cols="4">
                    <basic-select
                      name="from"
                      :solid="false"
                      :allowEmpty="false"
                      value-label="text"
                      track-by="value"
                      class="mb-0 mx-4"
                      :options="flags"
                      :value.sync="answer.flag"
                      changeValueByObject
                      :disabled="isDetail"
                      @update:value="selectedFlag"
                    />
                  </b-col>
                  <b-col>
                    <b-form-input
                      v-model="answer.name"
                      placeholder="Nhập mô tả"
                      disabled
                    />
                  </b-col>
                  <b-col cols="2">
                    <b-button
                      v-if="!isDetail"
                      class="btn btn-delete ml-0 mt-1"
                      size="sm"
                      type="button"
                      @click="removeRatingAnswer(answer)"
                    >
                      <span class="svg-icon mr-0">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Basic/trash-2.svg"
                        />
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </template>
            <span v-if="showErrorAnswers" class="text-danger"
              >Câu trả lời cho câu hỏi là chưa phù hợp</span
            >
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button class="btn ml-3" type="button" @click="cancelModal">
            Huỷ
          </b-button>
          <b-button v-if="!isDetail" class="btn btn-success ml-3" type="submit">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Lưu
          </b-button>
        </div>
        <ModalQuizeAvailable
          v-if="showModalQuizeAvailable"
          :show="showModalQuizeAvailable"
          @reset="resetModalAddQuestion"
          @add-question-avaiable="submitModalAddQuestion"
        />
      </form>
    </b-overlay>
  </b-modal>
</template>
<script>
import Sortable from 'sortablejs';
import { sortBy, differenceBy } from 'lodash';
import { MODAL_TYPE } from '@/core/plugins/constants';
import { createNamespacedHelpers } from 'vuex';
import { EDIT_QUESTION_GROUP } from '@/core/services/store/survey.module';
import { MODAL_STATUS } from '@/core/plugins/constants';
const { mapActions, mapState } = createNamespacedHelpers('survey');
export default {
  name: 'ModalQuestion',
  components: {
    ModalQuizeAvailable: () => import('./ModalQuizeAvailable'),
  },
  props: {
    type: {
      type: String,
      default: MODAL_TYPE.CREATE,
    },
    id: {
      type: String,
      default: null,
    },
    question: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        isScore: false,
        isRelatedQuestions: false,
        isRelatedPatients: false,
        type: {
          text: 'Single choice',
          value: 1,
        },
        code: null,
        name: null,
        order: null,
        relatedQuestion: null,
        relatedAnswer: null,
        source: null,
      },
      answers: [],
      answer: {
        isAddNew: true,
        name: null,
        score: null,
      },
      itemKeys: new WeakMap(),
      currentItemKey: 0,
      questionTypes: [
        {
          text: 'Single choice',
          value: 1,
        },
        {
          text: 'Multiple choices',
          value: 2,
        },
        {
          text: 'Text',
          value: 3,
        },
        {
          text: 'Rating',
          value: 4,
        },
      ],
      flags: Array.from({ length: 11 }, (_, i) => {
        return {
          text: `${i}`,
          value: `${i}`,
        };
      }),
      loading: false,
      orders: [],
      isRequireAnswer: false,
      isRatingRequired: false,
      isRequireScore: false,
      ratingAnswers: [],
      deletedAnswer: [],
      validCode: false,
      showErrorAnswers: false,
      showModalQuizeAvailable: false,
      answerLength: 26,
    };
  },
  computed: {
    ...mapState(['questions', 'lastOrder']),
    title() {
      switch (this.type) {
        case MODAL_TYPE.CREATE:
          return 'Tạo câu hỏi';
        case MODAL_TYPE.EDIT:
          return 'Chỉnh sửa câu hỏi';

        default:
          return 'Xem chi tiết câu hỏi';
      }
    },
    show: {
      get() {
        return this.$store.state.context.modalShow;
      },
      set(val) {
        this.$store.commit(`context/setModal`, val);
      },
    },
    headers() {
      if (!this.isDetail && !this.form.source) {
        return [
          {
            text: '',
            value: 'actionMove',
            sortable: false,
            width: '1%',
          },
          {
            text: 'Câu trả lời',
            value: 'answer',
            sortable: false,
          },
          {
            text: 'Điểm',
            value: 'score',
            sortable: false,
          },
          {
            text: '',
            value: 'action',
            sortable: false,
            width: '1%',
          },
        ];
      }
      return [
        {
          text: 'Câu trả lời',
          value: 'answer',
          sortable: false,
        },
        {
          text: 'Điểm',
          value: 'score',
          sortable: false,
          align: 'center',
        },
      ];
    },
    showAnswers() {
      if (this.form.type && this.form.type.value !== 3) return true;
      return false;
    },
    isRating() {
      if (this.form.type && this.form.type.value === 4) return true;
      return false;
    },
    showScoreCheckbox() {
      if (this.form.type && [1, 2].includes(this.form.type.value)) return true;
      return false;
    },
    isEdit() {
      return this.type === MODAL_TYPE.EDIT;
    },
    isDetail() {
      return this.type === MODAL_TYPE.DETAIL;
    },
    isCreate() {
      return this.type === MODAL_TYPE.CREATE;
    },
    answersOfQuestion() {
      if (!this.form.relatedQuestion) return [];
      return this.form.relatedQuestion.answers;
    },
    relatedQuestions() {
      if (!this.isEdit) return this.questions;
      return [...this.questions].filter((el) => el.order < this.question.order);
    },
    isRelatedQuestion() {
      if (!this.questions.length || !this.question) return false;
      const reletedQuestion = this.questions.find(
        (el) => el.mappedQuestionId === this.question.id,
      );
      return reletedQuestion ? true : false;
    },
  },
  watch: {
    orders: {
      handler(val) {
        if (this.isCreate) {
          this.form.order = this.selectDropdown(this.lastOrder, val);
        }
      },
      deep: true,
    },
    isRating(val) {
      if (val) {
        this.answer.flag = null;
        !this.isDetail && this.ratingAnswers.push(this.answer);
      } else {
        delete this.answer.flag;
        this.ratingAnswers = [];
      }
    },
    'form.code': function (val) {
      let code = null;
      if (this.question?.code) {
        code = this.question.code;
      }
      if (!val || code === val) return (this.validCode = false);
      this.checkDuplicateCode(val);
    },
  },
  mounted() {
    if (!this.isRating) {
      setTimeout(() => {
        new Sortable(
          this.$refs.sortableTable.$el.getElementsByTagName('tbody')[0],
          {
            draggable: '.sortableRow',
            handle: '.sortHandle',
            onEnd: this.dragReorder,
          },
        );
      }, 100);
    }
    this.setupData();
  },
  methods: {
    ...mapActions({ EDIT_QUESTION_GROUP }),
    setupData() {
      if (this.isEdit) {
        this.orders = Array.from({ length: this.lastOrder - 1 }, (_, i) => {
          return {
            text: `${i + 1}`,
            value: i + 1,
          };
        });
      } else {
        this.orders = Array.from({ length: this.lastOrder }, (_, i) => {
          return {
            text: `${i + 1}`,
            value: i + 1,
          };
        });
      }
      if (!this.isCreate) {
        this.form.code = this.question.code;
        this.form.name = this.question.name;
        this.form.order = this.selectDropdown(this.question.order, this.orders);
        this.form.isScore = this.question.isScore;
        this.form.isRelatedPatients = this.question.isRelatedPatients;
        this.form.isRelatedQuestions = this.question.isRelatedQuestions;
        this.form.relatedQuestion = this.question.mappedQuestionId
          ? this.selectedItem(
              this.question.mappedQuestionId,
              this.relatedQuestions,
            )
          : this.question.relatedQuestion;
        this.form.relatedAnswer = this.question.mappedAnswerId
          ? this.selectedItem(
              this.question.mappedAnswerId,
              this.answersOfQuestion,
            )
          : this.question.relatedAnswer;
        this.form.type = this.questionTypes.find(
          (el) => el.value === this.question.type,
        );

        if (this.form.isRelatedQuestions && this.form.relatedQuestion) {
          const question = this.questions.find(
            (el) => el.id === this.question.mappedQuestionId,
          );
          this.orders = this.orders.filter(
            (el) =>
              el.value <= this.question.order && el.value > question.order,
          );
        }

        this.answers = sortBy(
          this.question.answers.map((el) => {
            return {
              ...el,
              name: el.content,
              score: el.point,
            };
          }),
          'order',
        );
        this.form.source = this.question.source || null;
      }
      if (!this.isDetail && this.showScoreCheckbox && !this.form.source) {
        this.answers.push(this.answer);
      }
      if (this.isRating && !this.isCreate) {
        const answers = [...this.question.answers].map((el) => {
          return {
            ...el,
            flag: this.selectDropdown(`${el.flag}`, this.flags),
            name: el.content,
          };
        });
        this.ratingAnswers = [...answers, ...this.ratingAnswers];
        const selectedFlags = [...answers].map((el) => {
          return {
            text: el.flag.text,
            value: el.flag.value,
          };
        });
        this.flags = this.filterFlags(selectedFlags);
      }
    },
    resetModal() {
      this.$store.commit(`context/setModal`, false);
      this.$emit('reset');
    },
    cancelModal() {
      this.$store.commit(`context/setModal`, false);
    },
    removeAnswer(item) {
      this.answers = [...this.answers].filter((el) => el.id !== item.id);
      const indexAddNew = this.answers.findIndex((el) => el.isAddNew);
      if (indexAddNew === -1) {
        this.answers.push(this.answer);
      }

      if (this.$route.name === 'survey_quize_edit' && item.modelStatus === 0) {
        item.modelStatus = MODAL_STATUS.DELETE;
        this.deletedAnswer.push(item);
      }
    },
    async addAnswer(item) {
      const newItem = {
        name: item.name,
        score: item.score,
        id: this.$uuid.v1(),
      };

      await (this.isRequireAnswer = !newItem.name);
      await (this.isRequireScore = !newItem.score);
      await this.delay(100);
      const scoreValid = await this.$validator.validate('score');
      const answerValid = await this.$validator.validate('add-answer');
      if (!answerValid || !scoreValid) return;

      this.answers = this.insert(
        this.answers,
        this.answers.length - 1,
        newItem,
      );

      if (this.answers.length >= this.answerLength) {
        this.answers = [...this.answers].filter((el) => !el.isAddNew);
      }

      this.answer.name = null;
      this.answer.score = null;
      this.showErrorAnswers = false;
      await this.$validator.reset({ name: 'add-answer' });
      await this.$validator.reset({ name: 'score' });
    },
    async addRatingAnswer(item) {
      const newItem = {
        name: item.name,
        flag: item.flag,
        id: this.$uuid.v1(),
      };
      await (this.isRatingRequired = !newItem.name || !newItem.flag);

      await this.delay(50);
      const flagValid = await this.$validator.validate('flag');
      const explainValid = await this.$validator.validate('explain');

      if (!flagValid || !explainValid) return;

      this.ratingAnswers = this.insert(
        this.ratingAnswers,
        this.ratingAnswers.length - 1,
        newItem,
      );

      if (this.ratingAnswers.length === this.answerLength) {
        this.ratingAnswers.pop();
      }
      this.flags = [...this.flags].filter(
        (el) => el.value !== newItem.flag.value,
      );
      this.answer.flag = null;
      this.answer.name = null;
      this.showErrorAnswers = false;
      await this.$validator.reset({ name: 'flag' });
      await this.$validator.reset({ name: 'explain' });
    },
    removeRatingAnswer(item) {
      this.ratingAnswers = this.ratingAnswers.filter((el) => el.id !== item.id);
      const selectedFlags = [...this.ratingAnswers]
        .filter((el) => !el.isAddNew)
        .map((el) => {
          return {
            text: el.flag.text,
            value: el.flag.value,
          };
        });
      this.flags = this.filterFlags(selectedFlags);
      if (item.modelStatus === 0) {
        item.modelStatus = MODAL_STATUS.DELETE;
        this.deletedAnswer.push(item);
      }
    },
    itemKey(item) {
      if (!this.itemKeys.has(item))
        this.itemKeys.set(item, ++this.currentItemKey);
      return this.itemKeys.get(item);
    },
    dragReorder({ oldIndex, newIndex }) {
      const movedItem = this.answers.splice(oldIndex, 1)[0];
      this.answers.splice(newIndex, 0, movedItem);
    },
    selectType(value) {
      this.form.type = this.selectDropdown(value, this.questionTypes);
      if (
        this.showAnswers &&
        this.$route.name === 'survey_quize_edit' &&
        this.question.answers.length
      ) {
        const originalAnswers = [...this.question.answers].map((el) => {
          return {
            ...el,
            modelStatus: MODAL_STATUS.DELETE,
          };
        });
        this.deletedAnswer = [...this.deletedAnswer, ...originalAnswers];
      }
      if (this.answers.length) {
        this.answers = [this.answers[this.answers.length - 1]];
      } else {
        this.answers.push(this.answer);
      }
      this.$validator.reset();
      this.showErrorAnswers = false;
    },
    selectOrder(value) {
      this.form.order = this.selectDropdown(value, this.orders);
    },
    selectRateFrom(value) {
      this.form.rateFrom = this.selectDropdown(value, this.ratingFrom);
    },
    selectRateTo(value) {
      this.form.rateTo = this.selectDropdown(value, this.ratingTo);
    },
    selectDropdown(value, array) {
      let selected = null;
      if (value) {
        selected = array.find((el) => el.value === value);
      } else {
        selected = null;
      }
      return selected;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    async handleValidate() {
      await (this.isRequireScore = false);
      await (this.isRequireAnswer = false);
      await (this.validCode = false);
      if (this.showAnswers) {
        const answers = this.answers.filter((el) => !el.isAddNew);
        await (this.showErrorAnswers = !this.isRating
          ? answers.length <= 0
          : this.ratingAnswers.length <= 1);
      }
      await this.delay(50);
      await this.$validator.reset({ name: 'add-answer' });
      this.$validator.validateAll().then((result) => {
        if (result && !this.showErrorAnswers) {
          this.submit();
        }
        return;
      });
    },
    submit() {
      const params = {
        name: this.form.name,
        code: this.form.code,
        order: this.form.order.value,
        type: this.form.type.value,
        isScore: this.form.isScore,
        isRelatedQuestions: this.form.isRelatedQuestions,
        isRelatedPatients: this.form.isRelatedPatients,
        mappedQuestionId: this.form.relatedQuestion?.id,
        mappedAnswerId: this.form.relatedAnswer?.id,
        answers: this.getAnswers(),
      };

      if (this.isCreate) {
        params['modelStatus'] = MODAL_STATUS.NEW;
        params['answers'] = this.getAnswerParams([], params.answers);
        return this.updateQuestion(params);
      }
      params['id'] = this.question.id;
      params['modelStatus'] = this.question.modelStatus;

      params['answers'] = this.getAnswerParams(
        this.question.answers,
        params.answers,
      );

      const result = this.isEqualTwoObjects(
        this.removeEmpty(this.question),
        this.removeEmpty(params),
      );
      if (
        !result ||
        params.answers.filter((el) => el.modelStatus !== MODAL_STATUS.UNCHANGE)
          .length
      ) {
        params['modelStatus'] = MODAL_STATUS.CHANGED;
      }

      this.updateQuestion(params);
    },
    getAnswerParams(defaultItems, beforeSubmitItems) {
      let answers = [];

      const answerParamsPush = (array, status, type = 1) => {
        array.forEach((answer) => {
          let params = {
            ...answer,
            id: type ? answer.id : null,
            modelStatus: status,
          };
          answers.push(this.removeEmpty(params));
        });
      };

      let [commonItems, deletedItems, addNewItems] = this.getItemWithStatus(
        defaultItems,
        beforeSubmitItems,
      );
      let changedItems = this.getChangedItems(defaultItems, beforeSubmitItems);

      if (changedItems.length) {
        commonItems = differenceBy(commonItems, changedItems, 'id');
      }
      const params = [
        {
          value: changedItems,
          status: MODAL_STATUS.CHANGED,
          type: 1,
        },
        {
          value: commonItems,
          status: MODAL_STATUS.UNCHANGE,
          type: 1,
        },
        {
          value: deletedItems,
          status: MODAL_STATUS.DELETE,
          type: 1,
        },
        {
          value: addNewItems,
          status: MODAL_STATUS.NEW,
          type: 0,
        },
      ];

      params.forEach((el) => {
        answerParamsPush(el.value, el.status, el.type);
      });
      return answers;
    },
    getAnswers() {
      let answers = [];
      if (!this.isRating) {
        answers = [...this.answers].filter((el) => !el.isAddNew);
        return answers.map((el, index) => {
          const params = {
            content: el.name,
            point: Number(el.score),
            id: el.id,
            order: index + 1,
            modelStatus:
              el.modelStatus || el.modelStatus === 0 ? el.modelStatus : null,
          };
          return this.removeEmpty(params);
        });
      }
      answers = [...this.ratingAnswers].filter((el) => !el.isAddNew);
      answers = answers.map((el) => {
        return {
          ...el,
          flag: Number(el.flag.value),
        };
      });
      answers = sortBy(answers, ['flag']);
      return answers.map((el, index) => {
        const params = {
          content: el.name,
          flag: el.flag,
          id: el.id,
          order: index + 1,
        };
        return this.removeEmpty(params);
      });
    },
    getChangedItems(defaultItems, beforeSubmitItems) {
      const changedItems = [];
      defaultItems.forEach((item) => {
        const element = beforeSubmitItems.find((el) => el.id === item.id);
        if (!element) return;

        if (!this.isRating) {
          if (element.point === item.point && element.order === item.order)
            return;
        } else if (element.flag === item.flag && element.order === item.order)
          return;
        changedItems.push(element);
      });
      return changedItems;
    },
    async updateQuestion(params) {
      this.loading = true;
      const payload = {
        id: this.id,
        name: null,
        code: null,
        questions: [],
      };
      if (this.isCreate) {
        payload['questions'] = [...this.questions, ...[params]];
      } else {
        const questionIndex = this.questions.findIndex(
          (el) => el.id === params.id,
        );

        this.questions[questionIndex] = params;
        payload['questions'] = this.questions;
      }

      const { error } = await this.EDIT_QUESTION_GROUP(payload);
      if (error) {
        this.loading = false;
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      }
      this.loading = false;
      this.resetModal();
    },
    changeRelatedQuestions() {
      this.form.isRelatedPatients = false;
    },
    changeRelatedPatients() {
      this.form.isRelatedQuestions = false;
    },
    addQuizeAvailable() {
      this.showModalQuizeAvailable = true;
    },
    resetModalAddQuestion() {
      this.showModalQuizeAvailable = false;
    },
    submitModalAddQuestion(params) {
      this.form.code = params.code;
      this.form.name = params.name;
      this.form.type = {
        text: 'Single choice',
        value: 1,
      };
      this.form.isScore = false;
      this.form.isRelatedQuestions = false;
      this.form.isRelatedPatients = false;
      this.form.source = 'quiz-avaiable';
      this.answers = [];
      const quizAnswers =
        params.quizAnswers?.map((el) => {
          return {
            ...el,
            score: el.isCorrect ? 1 : 0,
            modelStatus: MODAL_STATUS.NEW,
          };
        }) || [];
      this.answers = [...quizAnswers];
      this.resetModalAddQuestion();
    },
    filterFlags(avaiableFlag) {
      const flags = Array.from({ length: 11 }, (_, i) => {
        return {
          text: `${i}`,
          value: `${i}`,
        };
      });
      return differenceBy(flags, avaiableFlag, 'value');
    },
    async selectedFlag() {
      const selectedFlags = [...this.ratingAnswers]
        .filter((el) => !el.isAddNew)
        .map((el) => {
          return {
            text: el.flag.text,
            value: el.flag.value,
          };
        });
      this.flags = this.filterFlags(selectedFlags);
      await this.$validator.reset({ name: 'flag' });
    },
    async checkDuplicateCode(code) {
      try {
        const { meta, error, data } = await this.$api.get(
          '/SurveyQuestion/CheckDuplicateCode',
          {
            params: {
              code: code,
            },
          },
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.validCode = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
    },
    selectedItem(id, array) {
      return array.find((el) => el.id === id);
    },
    selectedQuestion() {
      this.form.relatedAnswer = null;
      this.$validator.reset({ name: `realted-question` });
    },
  },
};
</script>
<style lang="scss" scoped>
.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}
.btn-save {
  color: #008479 !important;
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
}
.btn-delete {
  color: #ff5756 !important;
  background-color: #ffebef !important;
  border-color: #ffebef !important;
}
</style>

<style lang="scss">
.score-input {
  .form-group {
    margin-bottom: 0 !important;
  }
}

#modal-question {
  .modal-dialog {
    max-width: 70%;
  }
}
</style>
